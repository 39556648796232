import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Header from "../../components/ContactPage/Header"
import OfficeComponent from "../../components/ContactPage/OfficeComponent"
import MapComponent from "../../components/ContactPage/MapComponent"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl, navigate } from "gatsby-plugin-react-intl"
import TextComponent from "../../components/ContactPage/TextComponent"
import image1 from "../../images/niepolomice-image1.jpg"
import image2 from "../../images/niepolomice-image2.jpg"
import SecondSection from "../../components/ContactPage/SecondSection"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import OfficeWithoutContentful from "../../components/ContactPage/OfficeComponent/OfficeWithoutContentful"
import BochniaMap from "../../components/ContactPage/MapComponent/BochniaMap"
import BochniaHeader from "../../components/ContactPage/Header/BochniaHeader"
import bochniaimage from "../../images/bochnia-image.jpg"

const ImageBox = styled.div`
  width: 100%;
  aspect-ratio: 16/8;
  display: flex;
  justify-content: center;
  margin-bottom: 78px;

  img {
    width: 100%;
    height: 100%;
  }
`
const H3Box = styled.div`
  h3 {
    margin-bottom: 10px;

    font-size: 2.2rem;
    font-weight: 700;
    line-height: 3.3rem;
    letter-spacing: 0.04em;

    background: linear-gradient(
      90.75deg,
      #bb913c -4.54%,
      #eae69c 43.88%,
      #ce9638 101.11%
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const ContactPage = () => {
  const intl = useIntl()
  const lang = intl.locale
  const [data, setData] = useState({})
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (lang === "en") {
      navigate("/contact/bochnia/")
    }
  }, [lang])

  return (
    <Layout>
      <Seo
        title="Dębosz - Biuro Nieruchomości Bochnia"
        renderSchemaLd={true}
        description="Odwiedź nasze biuro w Bochni. Nasze biuro współpracuje z najlepszymi Agentami nieruchomości!"
      />
      <BochniaHeader
        contact_niepolomice={"Biuro nieruchomości Bochnia"}
        goldHeader={false}
      />
      <OfficeWithoutContentful
        office_niepolomice={""}
        debosz_nieruchomosci={""}
        rynek={""}
        opening_hours={""}
      />
      <BochniaMap location={"Lokalizacja:"} />
      <TextComponent>
        <h1>Biuro Nieruchomości Bochnia</h1>
        <p>Szukasz idealnej nieruchomości dla siebie i swojej rodziny?</p>
        <p>Potrzebujesz wsparcia w sprzedaży nieruchomości?</p>
        <h4>Zapraszamy do naszego biura nieruchomości!</h4>
        <p>
          Jesteśmy profesjonalistami, którzy doskonale znają lokalny rynek
          nieruchomości. Cechuje nas indywidualne podejście do Klientów oraz
          pełne zaangażowanie na każdym etapie współpracy.
        </p>
        <p>
          Dzięki temu, że posiadamy wieloletnie doświadczenie, jeżeli chodzi o
          sprzedaż domów i mieszkań, wynajem i zakup nieruchomości, działamy
          szybko i skutecznie!
        </p>
        <h5>Dołącz do grona naszych Klientów!</h5>
      </TextComponent>
      <TextComponent>
        <h2>Skorzystaj z naszej wiedzy o sprzedaży nieruchomości!</h2>
        <p>
          Doskonale wiemy, że sprzedaż nieruchomości potrafi trwać naprawdę
          długo. Znalezienie Klienta, który zechce kupić dom czy mieszkanie w
          oferowanej przez Ciebie cenie, jest najczęściej czaso- i pracochłonne.
        </p>
        <p>
          Nasze biuro nieruchomości z przyjemnością wesprze Cię w procesie
          sprzedaży domu czy mieszkania. W zakresie usługi naszego biura
          znajdują się przygotowanie nieruchomości do sprzedaży, stworzenie i
          publikacja atrakcyjnego ogłoszenia, przeprowadzanie prezentacji, jak
          również negocjacje z potencjalnymi kupującymi.
        </p>
        <p>Każda nieruchomość na sprzedaż to dla nas kolejne wyzwanie!</p>
        <p>
          Szukasz profesjonalistów, którzy mają wieloletnie doświadczenie w
          branży nieruchomości?
        </p>
        <h5>Doskonale trafiłeś! Zapraszamy do współpracy!</h5>
        <h2>
          Biuro nieruchomości DĘBOSZ świadczy kompleksowe wsparcie w zakresie
          zakupu i wynajmu nieruchomości!
        </h2>
        <p>Zastanawiasz się nad zakupem lub wynajmem nieruchomości?</p>
        <p>
          Nasze biuro nieruchomości nie tylko oferuje usługi takie, jak wsparcie
          w zakupie czy wynajmie nieruchomości. Nasi Agenci również doradzają
          Klientom na każdym etapie współpracy.
        </p>
        <p>
          Jeżeli więc rozważasz kilka opcji (np. zakup nieruchomości z rynku
          wtórnego i budowę domu) i nie wiesz, na co się zdecydować - skontaktuj
          się z nami, a z pewnością razem znajdziemy najlepsze wyjście!
        </p>
      </TextComponent>
      <ImageBox>
        <img src={bochniaimage} alt="image" />
      </ImageBox>
      <TextComponent>
        <h2>Oferujemy kompleksowe wsparcie dla naszych Klientów!</h2>
        <p>
          Nasza oferta wsparcia jest bardzo szeroka. Obejmuje również
          finansowanie nieruchomości czy tworzenie filmów reklamowych
          nieruchomości.
        </p>
        <p>
          Wszystko po to, żebyś - niezależnie od typu transakcji - sfinalizował
          ją maksymalnie szybko i osiągnął pełne zadowolenie!
        </p>
        <h5>Zobacz nasze usługi!</h5>

        <h2>
          Indywidualne podejście to nie wszystko! Nasze biuro nieruchomości jest
          zawsze pół kroku przed innymi!
        </h2>
        <p>
          Biuro nieruchomości DĘBOSZ wyróżnia indywidualne podejście do Klienta,
          choć nie jest to jedyna zaleta naszej firmy.
        </p>
        <p>
          Doskonale znamy rynek nieruchomości w miastach Bochnia, Niepołomice i
          okolicy. W swoim portfolio posiadamy oferty na nieruchomości i
          działki, które są prawdziwymi "perełkami".
        </p>
        <p>
          Agenci naszego biura opiekują się Klientami przed, w trakcie i po
          transakcji. Dbają o to, by oferty kupna czy sprzedaży były jak
          najbardziej korzystne i w 100% spełniały oczekiwania Klienta.
        </p>
        <h5>Skontaktuj się z nami!</h5>
      </TextComponent>
      <TextComponent>
        <h2>Zobacz nasze oferty - jest z czego wybierać!</h2>
        <p>
          Szukasz działki do 200 000 PLN, mieszkania do 300 000 PLN, a może domu
          za max. 1 000 000 PLN?
        </p>
        <p>
          Nasze biuro obrotu nieruchomościami dysponuje tyloma interesującymi
          ofertami, że z pewnością będziesz mieć co oglądać!
        </p>
        <h5>Skorzystaj z wyszukiwarki i znajdź dla siebie najlepsze oferty!</h5>
      </TextComponent>

      <SecondSection image={image2}>
        <h2>Czy sprzedaż nieruchomości przez biuro się opłaca?</h2>
        <p>
          Współpraca z biurem nieruchomości ma wiele zalet i zdecydowanie się
          opłaca!
        </p>
        <p>
          Do najbardziej oczywistych zaliczają się przede wszystkim
          bezpieczeństwo na każdym etapie zawarcia transakcji czy oszczędność
          czasu.
        </p>
        <p>
          Nie można pominąć jednak także takich aspektów, jak możliwość
          obniżenia ceny zakupu nieruchomości dzięki skutecznym negocjacjom ze
          sprzedającym oraz zwiększenie wartości domu czy mieszkania dzięki
          profesjonalnemu przygotowaniu nieruchomości.
        </p>
      </SecondSection>

      <TextComponent>
        <h2>Zakup czy wynajem nieruchomości - co jest lepsze?</h2>
        <p>
          Nie ma dobrej odpowiedzi na to pytanie, ponieważ wiele zależy od
          możliwości finansowych Klienta, jego preferencji oraz oczekiwań.
        </p>
        <p>
          Mimo że mówi się, iż "lepiej płacić za swoje niż obcemu opłacać
          kredyt", nie zawsze wszystko jest tak zerojedynkowe.
        </p>
        <p>
          Druga sprawa jest taka, że nie każdy ma zdolność kredytową i czasami
          wynajem lokalu jest jedynym wyjściem, by mieszkać w dobrych warunkach.
        </p>
        <p>
          Jedno jest pewne - wśród ofert DĘBOSZ NIERUCHOMOŚCI na pewno
          znajdziesz wiele interesujących ogłoszeń mieszkań i domów na wynajem
          oraz na sprzedaż.
        </p>
        <h2>
          DĘBOSZ NIERUCHOMOŚCI - nieruchomości nie mają przed nami żadnych
          tajemnic!
        </h2>

        <p>
          Szukasz wymarzonego mieszkania w mieście Bochnia czy jego okolicy?
        </p>
        <p>A może planujesz budowę domu i rozglądasz się za działką?</p>
        <p>
          Agenci reprezentujący nasze biuro nieruchomości na pewno z
          przyjemnością się Tobą zaopiekują i znajdą dla Ciebie najlepszą
          ofertę!
        </p>
      </TextComponent>
      <TextComponent>
        <h2>
          Sprawdź zakres usług DĘBOSZ NIERUCHOMOŚCI - w czym możemy Ci pomóc?
        </h2>
        <p>
          Nasze biuro nieruchomości, wychodząc naprzeciw oczekiwaniom Klienta,
          posiada szeroki zakres usługi.
        </p>
        <p>
          W branży nieruchomości pracujemy już od lat więc doskonale wiemy, że
          pośrednictwo w sprzedaży, zakupie czy wynajmie to za mało.
        </p>
        <p>
          Dlatego też oferujemy m.in. doradztwo finansowe czy przygotowywanie
          filmów reklamowych.
        </p>

        <h5>
          Sprzedaż, zakup i wynajem nieruchomości z naszym biurem to czysta
          przyjemność!
        </h5>
      </TextComponent>
      <TextComponent>
        <H3Box>
          <h3>Porozmawiajmy o Twoich oczekiwaniach!</h3>
        </H3Box>
      </TextComponent>
    </Layout>
  )
}

export default ContactPage
